import React, { useState } from 'react';
import productUnifyLogo from '../../Assets/Svg/productunifylogo.svg';
import logo from '../../Assets/Svg/logo.svg';
import arrowright from '../../Assets/Svg/arrowright.svg';
import forms from '../../Assets/Svg/forms.svg';
import './style.css';
import Forms from './Forms';

const Product = () => {
  const [openForm, setOpenForm] = useState(false);

  return (
    <div className="fixed inset-x-0 top-10 pt-12 bg-[#fafafa]">
      <div className="header-productInfo py-2">
        <div className="header container py-3 w-11/12 mx-auto grid grid-cols-12">
          <div className="col-span-5 text-[20px] font-medium">
            Products by Unify
          </div>
          <div className="col-span-7">
            <div className="flex items-stretch gap-9 mb-4">
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-2">
                  <img src={logo} className="w-[52px] h-5 -m-1" alt="" />
                  <p>Unify Portal</p>
                </div>
                <div className="flex items-center space-x-2 mb-8">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://unifywebtest.unifyedu.ng"
                  >
                    Visit site
                  </a>
                  <img src={arrowright} alt="" />
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <div className="flex flex-col gap-4">
                  <div className="flex items-center gap-2">
                    <img src={productUnifyLogo} alt="" />
                    <p>Certification by Unify</p>
                  </div>
                  <div className="flex items-center space-x-2 mb-8">
                    <p>Visit site</p>
                    <img src={arrowright} alt="" />
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="flex items-center space-x-2 mb-4">
              <img src={forms} alt="" />
              <p>Unify forms</p>
            </div>
            <div
            onClick={() => {
              setOpenForm(!openForm);
            }}
            className="cursor-pointer flex items-center space-x-2 "
          >
            <p>Go to forms</p>
            <img src={arrowright} alt="img" />
          </div> */}
          </div>
        </div>
      </div>
      {openForm && (
        <Forms
          handleClose={() => {
            setOpenForm(!openForm);
          }}
        />
      )}
    </div>
  );
};

export default Product;
